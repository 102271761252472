import React, { useContext, useEffect, useState } from "react";
import { dataStore } from "../contexts/DataContext";
import {
  ActivatedExercise,
  Activity,
  Data,
  ExerciseState,
  SolvedExerciseState,
  SolvingExerciseState,
} from "../interfaces/Data";

/**
 * This hook is meant to handle the progression through a series by a user.
 * For now, it is as simple as retrieving a list of exercises, passing on the
 * feedback that is harcoded in the data, and incrementing the index of the
 * current exercise.
 * Envisionned development include:
 * * make the list of exercises dynamic, depending on the student's success or
 *   failure of the previous exercises (using AI)
 * * make the feedback dynamic, depending on the student and their success
 *   (using AI)
 * * store the student's history for the current series
 * * ...
 * @param moduleID
 * @param objectiveID
 */
const usePlaylistManager = (
  moduleID: string,
  objectiveID?: string
): {
  /** The list of exercises in the series */
  exercises: ActivatedExercise<any>[];
  currentExerciseIndex: number;
  /** A callback defining what should happen when the student clicks on the "validate" button of the gameplay, or an equivalent event is triggered */
  recordExerciseResult: (result: boolean) => void;
  /** A callback defining what should happen when the student clicks on the "next" button in the player, or an equivalent event is triggered */
  goToNextExercise: () => void;
  /** A callback to go to a specific activity within the playlist */
  goToExercise: (id: string) => void;
} => {
  const { data } = useContext(dataStore);
  const [exercises, setExercises] = useState<ActivatedExercise<any>[]>(
    getInitialExercisesFromData(data, moduleID, objectiveID)
  );
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);

  useEffect(() => {
    // TODO: Fallback if no exercise match the request. Discussion is needed as
    // to what the user should see in this case.
    setExercises(getInitialExercisesFromData(data, moduleID, objectiveID));
    setCurrentExerciseIndex(0);
  }, [data, moduleID, objectiveID]);

  useEffect(() => {
    // Update the state of the current exercise when the current exercise changes
    setExercises((exs) => {
      return exs.map((exercise, index) => {
        return index === currentExerciseIndex
          ? {
              ...exercise,
              state: {
                status: "solving",
                currentTry: 1,
              },
            }
          : exercise;
      });
    });
  }, [currentExerciseIndex]);

  return {
    exercises,
    currentExerciseIndex,

    recordExerciseResult: (result) => {
      if (exercises[currentExerciseIndex].state.status !== "solving")
        throw new Error(
          "Exercise cycle error: Status should be solving when recording result"
        );

      setExercises(
        exercises.map((exercise, index) => {
          return currentExerciseIndex === index
            ? {
                ...exercise,
                state: {
                  status: "solved",
                  result,
                  feedback: result
                    ? exercises[currentExerciseIndex].exercise.feedback[
                        (exercise.state as SolvingExerciseState).currentTry - 1
                      ]?.correct || {}
                    : exercises[currentExerciseIndex].exercise.feedback[
                        (exercise.state as SolvingExerciseState).currentTry - 1
                      ]?.incorrect || {},
                  next:
                    !result &&
                    (exercise.state as SolvingExerciseState).currentTry <
                      exercises[currentExerciseIndex].exercise.numberOfTries
                      ? "retry"
                      : currentExerciseIndex === exercises.length - 1
                      ? "endOfPlaylist"
                      : "newExercise",
                  currentTry: (exercise.state as SolvingExerciseState)
                    .currentTry,
                },
              }
            : exercise;
        })
      );
    },

    goToNextExercise: () => {
      if (exercises[currentExerciseIndex].state.status !== "solved")
        throw new Error(
          "Exercise cycle error: Status should be solved when going to next exercise"
        );

      if (
        (exercises[currentExerciseIndex].state as SolvedExerciseState).next ===
        "retry"
      )
        setExercises(
          exercises.map((exercise, index) =>
            index === currentExerciseIndex
              ? {
                  ...exercise,
                  state: {
                    status: "solving",
                    currentTry:
                      (exercise.state as SolvedExerciseState).currentTry + 1,
                  },
                }
              : exercise
          )
        );
      else setCurrentExerciseIndex(currentExerciseIndex + 1);
    },
    goToExercise: (id) => {
      const newCurrentIndex = exercises.findIndex(
        (exercise) => exercise.exercise.id === id
      );
      if (newCurrentIndex === -1) throw new Error(`Exercise ${id} not found`);
      setCurrentExerciseIndex(newCurrentIndex);
      setExercises(
        exercises.map((exercise, index) => {
          if (index === newCurrentIndex)
            return {
              ...exercise,
              state: { status: "solving", currentTry: 1 },
            };
          return {
            ...exercise,
            state: { status: "unsolved" },
          };
        })
      );
    },
  };
};

const getInitialExercisesFromData = (
  data: Data,
  moduleID: string,
  objectiveID?: string
): ActivatedExercise<any>[] => {
  const module = data.modules.find((module) => module.id === moduleID);
  if (!module) throw new Error("Module does not exist");
  let activities: Activity[];
  if (objectiveID) {
    if (!module.objectiveIDs.includes(objectiveID))
      throw new Error("Objective not part of module");
    const objective = data.objectives.find(
      (objective) => objective.id === objectiveID
    );
    if (!objective) throw new Error("Objective not found");
    activities = data.activities.filter((activity) =>
      objective.activityIDs.includes(activity.id)
    );
  } else {
    const objectives = data.objectives.filter((objective) =>
      module.objectiveIDs.includes(objective.id)
    );
    const activityIDs = objectives
      .map((objective) => objective.activityIDs)
      .flat();
    activities = data.activities.filter((activity) =>
      activityIDs.includes(activity.id)
    );
  }
  return activities
    .map((activity, activityIndex) =>
      activity.exerciseIDs.map((exerciseID, exerciseIndex) => {
        const exercise = data.exercises.find(
          (exercise) => exercise.id === exerciseID
        );
        if (!exercise) throw new Error(`Exercise ${exerciseID} not found`);
        return {
          exercise,
          hierarchy: {
            moduleID,
            objectiveID,
            activityID: activity.id,
            exerciseNumber: "" + (exerciseIndex + 1),
          },
          state: (activityIndex === 0 && exerciseIndex === 0
            ? { status: "solving", currentTry: 1 }
            : { status: "unsolved" }) as ExerciseState,
        };
      })
    )
    .flat();
};

export default usePlaylistManager;
