import React, { useContext, Fragment } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Loader from '../../components/Loader/Loader';
import Header from '../../components/Header/Header';
import Home from '../Home/Home';
import ModuleList from '../ModuleList/ModuleList';
import Dasboard from '../DashBoard/Dasboard';
import ContentPage from '../ContentPage/ContentPage';
import PlaylistPlayer from '../PlaylistPlayer/PlaylistPlayer';
import SingleExercisePlayer from '../SingleExercisePlayer/SingleExercisePlayer';
import Logout from '../Logout/Logout';
import Error from '../Error/Error';
import Footer from '../../components/Footer/Footer';
import Dev from "../../components/Dev/Dev";

import { configStore } from "../../contexts/ConfigContext";
import useSetContexts from "../../hooks/useSetContexts"

import './Root.scss';

function Root() {

    const { config } = useContext(configStore);
    const { status } = useSetContexts()

    return (
        <Router>
            <Dev />
            { status.type === "fetched" ? 
                <Switch>
                    <Route path="/player/:moduleID/:objectiveID?" children={<PlaylistPlayer />} />
                    <Route path="/shell/:exerciseID" children={<SingleExercisePlayer />} />
                    <Fragment>
                        <div id="content">
                            <Header logo={config.logos.header_client} pages={config.pages} />
                            {
                                config.pages.map((page, i) =>
                                    <Route key={`route-${i}`} exact path={`/${page.url}`} render={
                                        (props) =>
                                            page.type === "HOME" ? <Home title={`${config.client_name} - ${page.label}`} /> :
                                            page.type === "MODULELIST" ? <ModuleList title={`${config.client_name} - ${page.label}`}/> :
                                            page.type === "DASHBOARD" && config.activate_dashboard ? <Dasboard title={`${config.client_name} - ${page.label}`} /> :
                                            page.type === "LOGOUT" ? <Logout /> :
                                            page.type === "CONTENTPAGE" ? <ContentPage title={`${config.client_name} - ${page.label}`}/> :
                                            <ContentPage title={`${config.client_name} - ${page.label}`}/> // TO DO : if no type assigned or wrong one => redirect to error page
                                    } />
                                )
                            }
                        </div>
                        <Footer
                            logos={config.logos}
                            pages={config.pages}
                            contact={config.contact}
                            i18n={{contact: config.i18n.misc.contact, developedBy: config.i18n.misc.developedBy}}
                        />
                    </Fragment>
                </Switch>
            : status.type === "error" ?
                <Error type={status.type} errors={status.errors} />
                : <Loader />
            }
        </Router>
    )
}

export default Root
