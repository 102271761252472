import React from "react";
import PlaySingleExercise from "../PlaySingleExercise/PlaySingleExercise";
import WindowContextLoader from "../WindowContextLoader/WindowContextLoader";

/**
 * This components adds development functionalities to the specimen.
 */
const Dev = () => {
  return <>
    <WindowContextLoader />
    <PlaySingleExercise />
  </>;
};

export default Dev;
