import React, { useState, useContext, useEffect } from "react";
import { Config } from "../interfaces/Config";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ContentPage } from "../interfaces/ContentPage";
import { dataStore } from "../contexts/DataContext";
import { configStore } from "../contexts/ConfigContext";
import { homeStore } from "../contexts/HomeContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { ErrorLog, Status } from "../interfaces/Status";
import windowLogger from "../utils/window-logger";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";

function useSetContexts() {
  const { setData } = useContext(dataStore);
  const { setHome } = useContext(homeStore);
  const { setContentPages } = useContext(contentPagesStore);
  const { setConfig } = useContext(configStore);

  const [status, setStatus] = useState<Status>({ type: "pending", errors: [] });
  const errorLog: ErrorLog[] = [];

  function getJson<T>(url: string): Promise<T> {
    return fetch(url, { cache: "no-cache" })
      .then((response) => {
        return response.json() as Promise<T>;
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        errorLog.push({ info: err, type: "JSON" });
        return err;
      });
  }

  const getAllJson = async function () {
    // Sequence of async fetch

    /* TEMP */
    const client = localStorage.getItem('client')
    /* --- */
    
    const config = await getJson<Config>(`https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/Module-1/config/config.json`);
    const home = await getJson<Home>(`https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/Module-1/config/home.json`);
    const contentPages = await getJson<ContentPage[]>(`https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/Module-1/config/contentPages.json`);
    const modules = await getJson<RawData>(`https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/Module-1/questions/data.json`);
    const exercises = await getExercisesWithAvailableGameplays(
      modules.exercises,
      (exercise, reason) => {
        windowLogger.error(
          `Exercise could not be imported: ${reason}`,
          exercise.id
        );
      }
    );
    console.log("CONSOLE config", config);

    // if no error is catch, set content and send fetched status, if error(s) send error status and log to display appropriate error page
    if (errorLog.length === 0) {
      setConfig({
        version: config.version,
        client_name: config.client_name,
        activate_dashboard: config.activate_dashboard,
        activate_contentPage: config.activate_contentPage,
        pages: config.pages,
        i18n: {
          hierarchy: {
            module: {
              full: config.i18n.hierarchy.module.full,
              short: config.i18n.hierarchy.module.short,
            },
            objective: {
              full: config.i18n.hierarchy.objective.full,
              short: config.i18n.hierarchy.objective.short,
            },
            exercise: {
              full: config.i18n.hierarchy.exercise.full,
              short: config.i18n.hierarchy.exercise.short,
            },
          },
          moduleList: {
            info: config.i18n.moduleList.info,
            title: config.i18n.moduleList.title,
          },
          endPlaylist: {
            congratulations: config.i18n.endPlaylist.congratulations,
            completed: config.i18n.endPlaylist.completed,
            result: config.i18n.endPlaylist.result,
            exerciseCorrect: config.i18n.endPlaylist.exerciseCorrect,
            exerciseIncorrect: config.i18n.endPlaylist.exerciseIncorrect,
            moduleList: config.i18n.endPlaylist.moduleList,
            nextModule: config.i18n.endPlaylist.nextModule,
            dashboard: config.i18n.endPlaylist.dashboard,
          },
          exerciseShell: {
            close: config.i18n.exerciseShell.close,
            gp: {
              btnValidate: config.i18n.exerciseShell.gp.btnValidate,
            },
            feedback: {
              nextExercise: config.i18n.exerciseShell.feedback.nextExercise,
              endPlaylist: config.i18n.exerciseShell.feedback.endPlaylist,
              retry: config.i18n.exerciseShell.feedback.retry,
            },
          },
          misc: {
            logout: config.i18n.misc.logout,
            contact: config.i18n.misc.contact,
            developedBy: config.i18n.misc.developedBy,
            colon: config.i18n.misc.colon,
          },
        },
        logos: {
          header_client: config.logos.header_client,
          endPlaylistIcon: config.logos.endPlaylistIcon,
          footer_client: config.logos.footer_client,
          footer_evidenceb: config.logos.footer_evidenceb,
        },
        contact: config.contact,
      });
      setHome({
        main: home.main,
        entries: home.entries,
        secondaries: home.secondaries,
      });
      setContentPages(contentPages);
      setData({
        modules: modules.modules,
        objectives: modules.objectives,
        activities: modules.activities,
        exercises,
      });
      setStatus({ ...status, type: "fetched" });
    } else {
      setStatus({ type: "error", errors: errorLog });
    }
  };

  useEffect(() => {
    console.log("useSetContexts => status", status);
    getAllJson();
  }, []);

  return { status };
}

export default useSetContexts;
