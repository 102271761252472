import React, { useContext } from "react";
import { Helmet } from 'react-helmet';

import ModuleListItem from "../../components/ModuleListItem/ModuleListItem";
import { Objective } from "../../interfaces/Data";
import "./ModuleList.scss";

import { dataStore } from "../../contexts/DataContext";
import { configStore } from "../../contexts/ConfigContext";

import { Title } from '../../interfaces/Config';

function ModuleList({ title }: Title) {
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);

  return (
    <>
      <Helmet> <title>{title}</title> </Helmet>
      <div id="modules-list">
        <h1>{config.i18n.moduleList.title}</h1>
        {config.i18n.moduleList.info !== "" ?
          <div className="info">
            <p>{config.i18n.moduleList.info}</p>
          </div> : null}
        {data.modules.map((module, i) => (
          <ModuleListItem
            key={"item" + i}
            id={module.id}
            title={module.title}
            description={module.description}
            objectives={module.objectiveIDs
              .map(objectiveID => data.objectives.find(objective => objective.id === objectiveID))
              .filter(objective => typeof objective !== "undefined") as Objective[]
            }
          />
        ))}
      </div>
    </>
  );
}

export default ModuleList;
