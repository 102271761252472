import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";

import "./ExerciseIdentifier.scss";

const ExerciseIdentifier = ({
  moduleID,
  objectiveID,
  exerciseID,
}: {
  moduleID: string;
  objectiveID?: string;
  exerciseID: string;
}) => {
  const {
    config: { i18n, logos },
  } = useContext(configStore);

  return (
    <div id="exercise-identifier">
      <div className="container">

        <div className="logo">
          <img src={logos.header_client} />
        </div>
        <div className="identifier">
          <span>{i18n.hierarchy.module.short}</span>.{moduleID}
          {objectiveID ? (
            <>
              {" "}
          - <span>{i18n.hierarchy.objective.short}</span>.{objectiveID}
            </>
          ) : null}
          <>
            {" "}
        - <span>{i18n.hierarchy.exercise.short}</span>.{exerciseID}
          </>

        </div>
      </div>

    </div>
  );
};

export default ExerciseIdentifier;
