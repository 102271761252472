import React, { useContext, useRef } from "react";
import { dataStore } from "../../contexts/DataContext";
import { Helmet } from "react-helmet";

import "./PlaylistBreadcrumbs.scss";
import { configStore } from "../../contexts/ConfigContext";
import { Activity } from "../../interfaces/Data";

export interface PlaylistBreadcrumbsProps {
  moduleID: string;
  objectiveID?: string;
  activityID?: string;
}

const PlaylistBreadcrumbs = ({
  moduleID,
  objectiveID,
  activityID,
}: PlaylistBreadcrumbsProps) => {
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);

  const infoContainerRef = useRef<HTMLDivElement>(null);

  const module = data.modules.find((module) => module.id === moduleID);
  if (!module) throw new Error("Module not found");
  const objective = objectiveID
    ? data.objectives.find((objective) => objective.id === objectiveID)
    : null;
  if (objectiveID && !objective) throw new Error("Objective not found");
  let activity: Activity | undefined;
  if (activityID) {
    activity = data.activities.find(
      (activity) => activity.id === activityID
    );
    if (!activity) throw new Error("Activity not found");
  }

  const mouseOverHandler = () => {
    infoContainerRef.current?.classList.add("show");
  };
  const mouseLeaveHandler = () => {
    infoContainerRef.current?.classList.remove("show");
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>{`${module.title} ${objective ? objective.title : ""}`}</title>{" "}
      </Helmet>
      <div className="header_breadcrumbs">
        <span>
          {module.title}
          {objective ? <> - {objective.title}</> : null}
        </span>
        {activity && (activity.title || activity.description) ? (
          <button
            onMouseOver={mouseOverHandler}
            onMouseLeave={mouseLeaveHandler}
          >
            <span className="material-icons">info_outline</span>
            <div ref={infoContainerRef} className="info">
              {activity.title}
              {activity.description && activity.title ? (
                <>{config.i18n.misc.colon}</>
              ) : null}
              {activity.description}
            </div>
          </button>
        ) : null}
      </div>
    </>
  );
};

export default PlaylistBreadcrumbs;
