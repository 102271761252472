import React from "react";
import { ExerciseState, SolvedExerciseState } from "../../interfaces/Data";
import { ProgressBarProps } from "../ProgressBar/ProgressBar";

import "./TeacherProgressBar.scss";

const TeacherProgressBar = ({
  exercisesState,
  currentExerciseIndex,
}: ProgressBarProps) => {
  return (
    <div id="progress-bar">
      <div className="progress-bar__gauge">
        <div
          className="gauge__completed"
          style={{
            width:
              "" +
              (100 *
                exercisesState.filter((state) => state.status === "solved")
                  .length) /
                exercisesState.length +
              "%",
          }}
        ></div>
      </div>
      {typeof currentExerciseIndex !== "undefined" ? (
        <div
          className={`progress-bar__current-exercise ${
            exercisesState[currentExerciseIndex].status === "solved"
              ? (exercisesState[currentExerciseIndex] as SolvedExerciseState)
                  .result
                ? "current-exercise--correct"
                : "current-exercise--incorrect"
              : "current-exercise--solving"
          }`}
          style={{
            left:
              "" + (100 * currentExerciseIndex) / exercisesState.length + "%",
          }}
        >
          {exercisesState[currentExerciseIndex].status === "solved" ? (
            <span className="material-icons">
              {(exercisesState[currentExerciseIndex] as SolvedExerciseState)
                .result
                ? "done"
                : "clear"}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TeacherProgressBar;
