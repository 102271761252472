import React, { createContext, useState, Dispatch, SetStateAction } from "react";
import { Data } from "../interfaces/Data";

// Context Typing
interface DataContext {
  data: Data;
  setData: Dispatch<SetStateAction<Data>>
}

// Initial State
const initialState: Data = {
  modules: [],
  objectives: [],
  activities: [],
  exercises: []
};

// Context init
const dataStore = createContext<DataContext>({
  data: initialState,
  setData: () => null
});

// Provider init
const { Provider } = dataStore;

// Final setup
const DataProvider = ({ children }: { children: JSX.Element }) => {
  const [data, setData] = useState(initialState)
  return <Provider value={{ data, setData }}>{children}</Provider>;
};

export { dataStore, DataProvider };