import React from 'react'
import { Link } from 'react-router-dom';
import Button from '../Button/Button'
import { Page } from '../../interfaces/Config'
import './Footer.scss'

interface FooterProps {
    logos:{
        footer_client: string;
        footer_evidenceb: string;
    }
    pages:Page[];
    contact: string;
    i18n:{
        contact: string;
        developedBy:string;
    }
}

function Footer({logos, pages, contact, i18n}:FooterProps) {

/*     const goToContact = () => {
        window.open(contact,'_blank')
    } */
    
    const currentYear = new Date().getFullYear();

    return (
        <div id="footer">
            <div className="container">
                <img className="logo left" src={logos.footer_client} />
                <div className="links">
                    <nav>
                        {
                            pages.map((page, i) => 
                                page.inFooter ? 
                                    <li key={`footer-link-${i}`}>
                                        <Link to={page.url}>{page.label}</Link></li>
                                    : null
                                )
                        }
                    </nav>
                    <a href={contact} target="_blank">
                        <span className="material-icons">mail_outline</span>
                        <span>{i18n.contact}</span>
                    </a>
                   {/*  <Button style={"tertiary"} label={i18n.contact} icon={"mail_outline"} onClick={goToContact}/> */}
                </div>
                <div className="logo right">
                    <span>{i18n.developedBy}</span>
                    <img src={logos.footer_evidenceb} />
                </div>
            </div>
            <div className="copyright">© Copyright {currentYear} EvidenceB</div>
        </div>
    )
}

export default Footer
