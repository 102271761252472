import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { configStore } from "../../contexts/ConfigContext";
import usePlaylistManager from "../../hooks/usePlaylistManager";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ExerciseShell from "../../components/ExerciseShell/ExerciseShell";
import Loader from "../../components/Loader/Loader";
import PlaylistSummary from "../../components/PlaylistSummary/PlaylistSummary";
import PlaylistBreadcrumbs from "../../components/PlaylistBreadcrumbs/PlaylistBreadcrumbs";
import {
  SolvedExerciseState,
  SolvingExerciseState,
} from "../../interfaces/Data";
import ExercisePicker from "../../components/ExercisePicker/ExercisePicker";

import "./PlaylistPlayer.scss";

const PlaylistPlayer = () => {
  const { config } = useContext(configStore);
  const { moduleID, objectiveID } = useParams<{
    moduleID: string;
    objectiveID?: string;
  }>();
  const {
    exercises,
    currentExerciseIndex,
    recordExerciseResult,
    goToNextExercise,
    goToExercise,
  } = usePlaylistManager(moduleID, objectiveID);

  if (!exercises) return <Loader />;

  return (
    <div
      id="playlist-player-container"
      className={`player-container${
        currentExerciseIndex >= exercises.length ? " player--completed" : ""
      }`}
    >
      <PlayerHeader>
        <div className="breadcrumbs-container">
          <PlaylistBreadcrumbs
            moduleID={moduleID}
            objectiveID={objectiveID}
            activityID={exercises[currentExerciseIndex]?.hierarchy.activityID}
          />
          {currentExerciseIndex < exercises.length ? (
            <ExercisePicker
              exercises={exercises}
              currentExerciseID={exercises[currentExerciseIndex].exercise.id}
              onChooseExercise={(id) => {
                goToExercise(id);
              }}
            />
          ) : null}
        </div>
        <ProgressBar
          exercisesState={
            currentExerciseIndex < exercises.length
              ? exercises
                  .filter(
                    (exercise) =>
                      exercise.hierarchy.activityID ===
                      exercises[currentExerciseIndex].hierarchy.activityID
                  )
                  .map((exercise) => exercise.state)
              : exercises.map((exercise) => exercise.state)
          }
          currentExerciseIndex={
            currentExerciseIndex < exercises.length
              ? +exercises[currentExerciseIndex].hierarchy.exerciseNumber - 1
              : undefined
          }
        />
        <div className="header_close">
          <Link
            to={`/${
              config.pages.find((page) => page.type === "MODULELIST")?.url
            }`}
          >
            <span className="material-icons">clear</span>
            <span>{config.i18n.exerciseShell.close}</span>
          </Link>
        </div>
      </PlayerHeader>

      <main>
        {currentExerciseIndex >= exercises.length ? (
          <PlaylistSummary
            finishedPlaylist={exercises.map(
              (exercise) => exercise.state as SolvedExerciseState
            )}
          />
        ) : (
          <ExerciseShell
            key={
              (exercises[currentExerciseIndex].state as
                | SolvingExerciseState
                | SolvedExerciseState).currentTry
            } // Used to reinit the Gameplay when retrying and the data doesn't changes
            data={exercises[currentExerciseIndex].exercise.data}
            hierarchy={{
              moduleID: moduleID,
              objectiveID: objectiveID,
              exerciseID: exercises[currentExerciseIndex].exercise.id,
            }}
            defaultShowCorrectAnswer={
              exercises[currentExerciseIndex].exercise.showCorrectAnswer
            }
            state={exercises[currentExerciseIndex].state}
            i18n={{ btnValidate: config.i18n.exerciseShell.gp.btnValidate }}
            instruction={exercises[currentExerciseIndex].exercise.instruction}
            GameplayComponent={
              exercises[currentExerciseIndex].exercise.Gameplay
            }
            onGoToNextExercise={() => {
              goToNextExercise();
            }}
            onExerciseResult={(result) => {
              recordExerciseResult(result);
            }}
          />
        )}
      </main>
    </div>
  );
};

export default PlaylistPlayer;
