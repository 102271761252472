import React, { useEffect } from 'react';
import './Error.scss';
import {Status} from '../../interfaces/Status'

function Error( {type, errors}: Status ) {

    useEffect(() => {
        console.log("Error => errorType type",type);
        console.log("Error => errorType errors",errors);
    }, [])

    return (
        <div className="error-content">
            {/* { JSON.stringify(errors) } */}
            {
                errors.map( (error, i) => (
                    <div className="error" key={'error' + i}>
                        {/*TO DO : ERROR display depending of error type*/}
                        <p>Info : {error.info}</p>
                        <p>type : {error.type}</p>
                    </div>
                ))
            }
        </div>
    )
}

export default Error
