import { ExerciseData, Gameplay } from "@evidenceb/gameplay-interfaces";
import Case from "case";
import { Exercise } from "../interfaces/Data";

/**
 * Function that returns a list of exercises with their associated gameplay. If
 * the gameplay required for a provided exercise cannot be found, it if not
 * added to the returned list.
 * NB: If more than logging is required from the callback, have a look at https://github.com/EvidenceB/evb_application/pull/6#pullrequestreview-573054253
 * @param exerciseDefinitions The list of raw exercises
 * @param onExerciseError A callback that is called for every exercise for which the Gameplay cannot be imported (should be used for logging)
 */
export const getExercisesWithAvailableGameplays = async (
  exerciseDefinitions: ExerciseData<any>[],
  onExerciseError?: (exercise: ExerciseData<any>, reason: any) => void
): Promise<Exercise<any>[]> => {
  const settledPromises = await fetchGameplays(exerciseDefinitions);
  return settledPromises
    .map((settledPromise, index) => {
      if (
        settledPromise.status === "fulfilled" &&
        typeof settledPromise.value !== "undefined"
      )
        return settledPromise.value;
      else {
        if (onExerciseError)
          onExerciseError(
            exerciseDefinitions[index],
            settledPromise.status === "rejected"
              ? settledPromise.reason
              : `Gameplay ${exerciseDefinitions[index].type} is undefined`
          );
        return undefined;
      }
    })
    .filter((exercise) => typeof exercise !== "undefined") as Exercise<any>[];
};

/**
 * An asynchronous function that returns a list of promise results from
 * fetching a component for each provided raw exercise
 * @param exerciseDefinitions The list of raw exercise
 */
const fetchGameplays = async (
  exerciseDefinitions: ExerciseData<any>[]
): Promise<
  (PromiseFulfilledResult<Exercise<any>> | PromiseRejectedResult)[]
> => {
  return Promise.allSettled(
    exerciseDefinitions.map(async (exerciseDefinition) => ({
      ...exerciseDefinition,
      Gameplay: await fetchComponent(exerciseDefinition.type),
    }))
  );
};

/**
 * An asynchronous function that fetches the gameplay associated with a type
 * @param gameplayType 
 */
export const fetchComponent = async (
  gameplayType: string
): Promise<Gameplay<any>> => {
  // NB: Case needs to be used outside of the import statement, otherwise
  // otherwise webpack magic means that it will not be defined at the time of
  // the import and an error will be thrown. (TL;DR: Do not refactor plz)
  const gameplayPascal = Case.pascal(gameplayType);
  const gameplayKebab = Case.kebab(gameplayType);
  const module = await import(
    `@evidenceb/gameplays/build/Components/Gameplays/${gameplayPascal}/${gameplayKebab}`
  );
  return module.default;
};

export default fetchGameplays;
