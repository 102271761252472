import React, {MouseEvent } from 'react'

import './Button.scss'

interface ButtonProps{
    style:"primary" | "secondary" | "tertiary";
    centeredLabel?:boolean;
    label: string;
    icon?: string;
    onClick?: (e:MouseEvent) => void;
}

const Button = ({style, label, icon, centeredLabel, onClick}: ButtonProps)  => {

    return (
        <button className={`btn ${style} ${centeredLabel ? 'centered' : ''}`}
            onClick={onClick} >
            <span className={`label`}>{label}</span>
            {
                icon ?
                    <span className="icon-container-btn">
                        <span className="material-icons">{icon}</span>
                    </span>
                : null
            }
        </button>
    )
}

export default Button 