import WindowErrorLog from "../interfaces/WindowError";

const windowLogger = {
  log: (logLevel: WindowErrorLog["level"], message: string, exerciseID?: string): void => {
    if (!windowLogger.isInitialized()) windowLogger.initialize();

    ((window as any).errors as WindowErrorLog[]).push({
      level: logLevel,
      timestamp: Date.now(),
      message,
      exerciseID
    });
  },
  error: (message: string, exerciseID?: string): void => {
    windowLogger.log("error", message, exerciseID);
  },
  warning: (message: string, exerciseID?: string): void => {
    windowLogger.log("warning", message, exerciseID);
  },
  isInitialized: (): boolean => typeof (window as any).errors !== "undefined",
  initialize: (): void => {
    if (windowLogger.isInitialized()) throw new Error("Error log already initialize, delete before retrying.");
    
    (window as any).errors = [] as WindowErrorLog[];
  },
  delete: (): void => {
    (window as any).errors = undefined;
  }
};

export default windowLogger;