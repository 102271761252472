import React, { useEffect } from 'react';
import { ConfigProvider } from "./contexts/ConfigContext";
import { HomeProvider } from './contexts/HomeContext';
import { ContentPagesProvider } from './contexts/ContentPagesContext';
import { DataProvider } from "./contexts/DataContext";
import useTheme from './hooks/usetheme';
import Root from './pages/Root/Root';
import './scss/App.scss';
import Loader from './components/Loader/Loader';
import Error from './pages/Error/Error';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const App = () => {
  const { enableLinkTracking, trackPageView } = useMatomo();
  const themeState = useTheme();

  enableLinkTracking();
  useEffect(() => {
    trackPageView({});
  }, []);


  useEffect(() => {
    console.log("APP.js LOADED");
    console.log("APP.js themeState",themeState);
  }, [themeState])

  return (
    
      themeState.status === "loaded" ? 
        <DataProvider>
          <ConfigProvider>
            <HomeProvider>
              <ContentPagesProvider>
                <Root />
              </ContentPagesProvider>
            </HomeProvider>
          </ConfigProvider>
        </DataProvider>
    : themeState.status === "loading" ? <Loader />
    : <Error type={themeState.status} errors={[{info:"Theme loading Error", type:"Fetch Error"}]} />
    
  );
}

export default App;
