import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Developer component that allows you to play a single exercise by id from a
 * console command.
 */
const PlaySingleExercise = () => {
  const history = useHistory();

  useEffect(() => {
    (window as any).playExercise = (id: string) => {
      history.push(`/shell/${id}`);
    };
  });

  return <></>;
};

export default PlaySingleExercise;
