import React from 'react'
import './Logout.scss'

function Logout() {
    return (
        <div>
            LOGOUT PAGE
        </div>
    )
}

export default Logout
