export enum Pagetype {
    HOME = "HOME",
    MODULELIST = "MODULELIST",
    DASHBOARD = "DASHBOARD",
    LOGOUT = "LOGOUT",
    CONTENTPAGE = "CONTENTPAGE"
}

export interface Title{
    title: string
}

export interface Page {
    url: string;
    label: string;
    type: Pagetype;
    inTopNav: boolean;
    inFooter: boolean;
}

export interface Config {
    version: number;
    client_name:string;
    activate_dashboard: boolean;
    activate_contentPage: boolean;
    pages: Page[];
    i18n: {
        hierarchy: {
            module: {
                full: string;
                short: string;
            },
            objective: {
                full: string;
                short: string;
            },
            exercise: {
                full: string;
                short: string;
            }
        }
        moduleList: {
            info:string;
            title: string;
        },
        endPlaylist: {
            congratulations: string;
            completed: string;
            result: string;
            exerciseCorrect: string;
            exerciseIncorrect: string;
            moduleList: string;
            nextModule: string;
            dashboard: string;
        },
        exerciseShell: {
            close: string;
            gp:{
                btnValidate: string;
            },
            feedback: {
                nextExercise: string;
                endPlaylist: string;
                retry: string;
            },
        },
        misc: {
            logout: string;
            contact: string;
            developedBy: string;
            colon: string;
        }
    },
    logos: {
        header_client: string;
        endPlaylistIcon: string;
        footer_evidenceb: string;
        footer_client: string;
    },
    contact: string;
}