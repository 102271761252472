import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Exercise,
  SolvedExerciseState,
  SolvingExerciseState,
} from "../../interfaces/Data";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import Error from "../Error/Error";
import Loader from "../../components/Loader/Loader";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import ExerciseShell from "../../components/ExerciseShell/ExerciseShell";

import "./SingleExercisePlayer.scss";

const SingleExercisePlayer = () => {
  const { config } = useContext(configStore);
  const { data } = useContext(dataStore);
  const { exerciseID } = useParams<{ exerciseID: string }>();
  const history = useHistory();
  const [exerciseData, setExerciseData] = useState<Exercise<any> | undefined>(
    undefined
  );
  const [exerciseNotFound, setExerciseNotFound] = useState<boolean>(false);
  const [exerciseState, setExerciseState] = useState<
    SolvingExerciseState | SolvedExerciseState
  >({
    status: "solving",
    currentTry: 1,
  });

  useEffect(() => {
    const newExerciseData = data.exercises.find(
      (exercise) => exercise.id === exerciseID
    );
    if (!newExerciseData) setExerciseNotFound(true);
    else setExerciseData(newExerciseData);
  }, [data.exercises, exerciseID]);

  if (exerciseNotFound)
    return (
      <Error
        type="error"
        errors={[{ type: "404", info: `Exercise ${exerciseID} not found.` }]}
      />
    );

  if (typeof exerciseData === "undefined") return <Loader />;

  return (
    <div id="single-player-container" className="player-container">
      <PlayerHeader>
        <div className="header_close">
          <Link
            to={`/${
              config.pages.find((page) => page.type === "MODULELIST")?.url
            }`}
          >
            <span className="material-icons">clear</span>
            <span>{config.i18n.exerciseShell.close}</span>
          </Link>
        </div>
      </PlayerHeader>

      <main>
        <ExerciseShell
          key={exerciseState.currentTry}
          data={exerciseData.data}
          instruction={exerciseData.instruction}
          defaultShowCorrectAnswer={
            exerciseState.currentTry < exerciseData.numberOfTries
              ? false
              : exerciseData.showCorrectAnswer
          }
          state={exerciseState}
          GameplayComponent={exerciseData.Gameplay}
          onGoToNextExercise={() => {
            if ((exerciseState as SolvedExerciseState).next === "retry")
              setExerciseState({
                status: "solving",
                currentTry: exerciseState.currentTry + 1,
              });
            else
              history.push(
                `/${
                  config.pages.find((page) => page.type === "MODULELIST")?.url
                }`
              );
          }}
          onExerciseResult={(result) => {
            setExerciseState({
              status: "solved",
              result,
              feedback: result
                ? exerciseData.feedback[exerciseState.currentTry - 1].correct
                : exerciseData.feedback[exerciseState.currentTry - 1].incorrect,
              next:
                !result && exerciseState.currentTry < exerciseData.numberOfTries
                  ? "retry"
                  : "close",
              currentTry: exerciseState.currentTry,
            });
          }}
          i18n={config.i18n.exerciseShell.gp}
        />
      </main>
    </div>
  );
};

export default SingleExercisePlayer;
