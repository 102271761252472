import React from "react";
import parse from "html-react-parser";
import { ContentWithTitle } from "@evidenceb/gameplay-interfaces";

import "./FeedbackDisplay.scss";

const FeedbackDisplay = ({
  isCorrect,
  title,
  text,
  image,
  imageAlt,
}: ContentWithTitle & { isCorrect: boolean }) => {
  return (
    <div id="feedback-display">
      {typeof isCorrect !== "undefined" ? (
        <div
          className={
            "feedback_correctness " + (isCorrect ? "--correct" : " --incorrect")
          }
        >
          <div className="icon-container">
            <span className="material-icons">
              {isCorrect
                ? "sentiment_very_satisfied"
                : "sentiment_very_dissatisfied"}
            </span>
          </div>
        </div>
      ) : null}

        {title ? (
          <p>
            <span className={isCorrect ? "correct" : "incorrect"}>
              {parse(title)}
            </span>
          </p>
        ) : null}
      <div className="feedback__content">

        {text ? (
          <div className="feedback_explanation">
            <div className="content">{parse(text)}</div>
          </div>
        ) : null}

        {image ? <img src={image} alt={imageAlt} /> : null}
      </div>
    </div>
  );
};

export default FeedbackDisplay;
