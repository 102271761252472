import React, { createContext, useState, useEffect, Dispatch, SetStateAction } from "react";
import { ContentPage } from "../interfaces/ContentPage";

// Context typing
interface ContentPagesContext {
    contentPages: ContentPage[];
    setContentPages: Dispatch<SetStateAction<ContentPage[]>>
}

// Initial State
const initialState: ContentPage[] = []

// Context init
const contentPagesStore = createContext<ContentPagesContext>({
    contentPages: initialState,
    setContentPages: () => null
});

// Provider init
const { Provider } = contentPagesStore;

// Final setup
const ContentPagesProvider = ({ children }: { children: JSX.Element }) => {
    const [contentPages, setContentPages] = useState(initialState)
    useEffect(() => {
        console.log("ContentPagesContext.tsx => contentPages", contentPages);
    }, [contentPages])

    return <Provider value={{ contentPages, setContentPages }}>{children}</Provider>;
};

export { contentPagesStore, ContentPagesProvider };