import React, { createContext, useState, useEffect, Dispatch, SetStateAction } from "react";
import { Home } from "../interfaces/Home";

// Context typing
interface HomeContext {
    home: Home;
    setHome: Dispatch<SetStateAction<Home>>
}

// Initial State
const initialState: Home = {
    main: undefined,
    entries: [],
    secondaries: []
};

// Context init
const homeStore = createContext<HomeContext>({
    home: initialState,
    setHome: () => null
});

// Provider init
const { Provider } = homeStore;


// Final setup
const HomeProvider = ({ children }: { children: JSX.Element }) => {
    const [home, setHome] = useState(initialState)
    useEffect(() => {
        console.log("HomeContext.tsx => home", home);
    }, [home])

    return <Provider value={{ home, setHome }}>{children}</Provider>;
};

export { homeStore, HomeProvider };