import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { configStore } from "../../contexts/ConfigContext";
import Button from "../Button/Button";
import { Pagetype } from '../../interfaces/Config';
import { SolvedExerciseState } from "../../interfaces/Data";

import "./PlaylistSummary.scss";

export interface PlaylistSummaryProps {
  finishedPlaylist: SolvedExerciseState[];
}

const PlaylistSummary = ({ finishedPlaylist }: PlaylistSummaryProps) => {

  const { config } = useContext(configStore)
  const history = useHistory();

  const moduleList = () => {
    config.pages.forEach(page => {
      if (page.type === Pagetype.MODULELIST) {
        history.push(`/${page.url}`)
      }
    })

  }

  const dashboard = () => {
    config.pages.forEach(page => {
      if (page.type === Pagetype.DASHBOARD) {
        history.push(`/${page.url}`)
      }
    })
  }

  return (
    <div id="playlist-summary-container">
      <div className="content">
        <div className="icon">
          <img src={config.logos.endPlaylistIcon} />
        </div>
        <h1>
          <span>{config.i18n.endPlaylist.congratulations}</span>
          <span className="roboto">{config.i18n.endPlaylist.completed}</span>
        </h1>
        <p>{config.i18n.endPlaylist.result}</p>
        <ul>
          {finishedPlaylist.map((exercise, index) => (
            <li key={index}>
              {index + 1}:
              {exercise.result ? (
                <span className="--correct">{config.i18n.endPlaylist.exerciseCorrect}</span>
              ) : (
                  <span className="--incorrect">
                    {config.i18n.endPlaylist.exerciseIncorrect}
                  </span>
                )}
            </li>
          ))}
        </ul>
        <nav>
          <Button onClick={moduleList} style={"primary"} label={config.i18n.endPlaylist.moduleList} icon={"arrow_forward"} />
          {
            config.activate_dashboard ?
              <Button onClick={dashboard} style={"primary"} label={config.i18n.endPlaylist.dashboard} icon={"arrow_forward"} />
              : null
          }
        </nav>
      </div>
    </div>
  );
};

export default PlaylistSummary;
