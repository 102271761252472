import React from "react";

import "./PlayerHeader.scss";

export interface PlayerHeaderProps {
  children: any;
}

const PlayerHeader = ({ children }: PlayerHeaderProps) => {
  return <header className="player-header">{children}</header>;
};

export default PlayerHeader;
