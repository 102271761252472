import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { Config } from "../interfaces/Config";

// Context Typing
interface ConfigContext {
  config: Config;
  setConfig: Dispatch<SetStateAction<Config>>
}

// Initial State
const initialState: Config = {
  version: 0,
  client_name:'',
  activate_dashboard: false,
  activate_contentPage: false,
  pages: [],
  i18n: {
    hierarchy: {
      module: {
        full: "",
        short: ""
      },
      objective: {
        full: "",
        short: ""
      },
      exercise: {
        full: "",
        short: ""
      }
    },
    moduleList: {
      info:"",
      title: ""
    },
    endPlaylist: {
      congratulations: "",
      completed: "",
      result: "",
      exerciseCorrect: "",
      exerciseIncorrect: "",
      moduleList: "",
      nextModule: "",
      dashboard: ""
    },
    exerciseShell: {
      close: "Fermer",
      gp: {
        btnValidate: ""
      },
      feedback: {
        nextExercise: "",
        endPlaylist: "",
        retry: ""
      }
    },
    misc: {
      logout: "",
      contact: "",
      developedBy: "",
      colon: ""
    }
  },
  logos: {
    header_client: "",
    endPlaylistIcon: "",
    footer_client: "",
    footer_evidenceb: "",
  },
  contact: ""
};

// Context init
const configStore = createContext<ConfigContext>({
  config: initialState,
  setConfig: () => null
});

// Provider init
const { Provider } = configStore;

// Final setup
const ConfigProvider = ({ children }: { children: JSX.Element }) => {
  const [config, setConfig] = useState(initialState)
  return <Provider value={{ config, setConfig }}>{children}</Provider>;
};

export { configStore, ConfigProvider };