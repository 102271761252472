import Case from "case";
import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { ActivatedExercise } from "../../interfaces/Data";

import "./ExercisePicker.scss";

const ExercisePicker = ({
  exercises,
  currentExerciseID,
  onChooseExercise,
}: {
  exercises: ActivatedExercise<any>[];
  currentExerciseID: string;
  onChooseExercise: (id: string) => void;
}) => {
  const { data } = useContext(dataStore);
  const { config } = useContext(configStore);

  return (
    <select
      id="exercise-picker"
      value={currentExerciseID}
      onChange={(e) => {
        onChooseExercise(e.target.value);
      }}
    >
      {exercises
        .reduce((acc, curr) => {
          const groupIndex = acc.findIndex(
            (group) => group.activityID === curr.hierarchy.activityID
          );
          if (groupIndex === -1)
            return [
              ...acc,
              { activityID: curr.hierarchy.activityID, exercises: [curr] },
            ];
          return acc.map((group, index) =>
            index === groupIndex
              ? { ...group, exercises: [...group.exercises, curr] }
              : group
          );
        }, [] as { activityID: string; exercises: ActivatedExercise<any>[] }[])
        .map((group, index) => (
          <optgroup
            key={index}
            label={
              data.activities.find(
                (activity) => activity.id === group.activityID
              )?.title
            }
          >
            {group.exercises.map((exercise) => (
              <option
                key={exercise.exercise.id}
                value={exercise.exercise.id}
                className={`${
                  exercise.state.status === "solving" ? "option--current" : ""
                } ${
                  exercise.state.status === "solved"
                    ? exercise.state.result
                      ? "option--correct"
                      : "option--incorrect"
                    : ""
                }`}
              >
                {
                  data.activities.find(
                    (activity) => activity.id === group.activityID
                  )?.title
                }
                {config.i18n.misc.colon}
                {Case.pascal(config.i18n.hierarchy.exercise.short)}
                {". "} 
                {exercise.hierarchy.exerciseNumber}
              </option>
            ))}
          </optgroup>
        ))}
    </select>
  );
};

export default ExercisePicker;
