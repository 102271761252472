import React, { useContext, useEffect, useState, useRef } from "react";
import { MinixAPIStatement, Gameplay } from "@evidenceb/gameplay-interfaces";
import FeedbackDisplay from "../FeedbackDisplay/FeedbackDisplay";
import Button from "../Button/Button";
import { configStore } from "../../contexts/ConfigContext";
import { ExerciseState, SolvedExerciseState } from "../../interfaces/Data";
import parse from "html-react-parser";
import ExerciseIdentifier from "../ExerciseIdentifier/ExerciseIdentifier";

import "./ExerciseShell.scss";

export interface ExerciseShellProps<T> {
  /** Data specific to the exercise */
  data: T;
  /** Position of the exercise in the exercises hierarchy */
  hierarchy?: {
    moduleID: string;
    objectiveID?: string;
    exerciseID: string;
  };
  /** Whether or not the correct answer should be shown, as indicated by the data of the exercise. Can be overridden if the exercise should be retried. */
  defaultShowCorrectAnswer: boolean;
  state: ExerciseState;
  /** Gameplay component for the exercise */
  GameplayComponent: Gameplay<T>;
  /** Callback that should be called when wanting to get the next exercise. */
  onGoToNextExercise: () => void;
  /** Callback that should be called with the result (correct/incorrect) of the exercise when the exercise is finished. It may return a personalized feedback. */
  onExerciseResult: (result: boolean) => void;
  instruction?: string;
  i18n: {
    btnValidate: string;
  };
}

/**
 * The ExerciseShell instanciates the Gameplay component from the library and
 * handles interactions with it.
 */
const ExerciseShell = ({
  data,
  hierarchy,
  defaultShowCorrectAnswer,
  state,
  i18n,
  instruction,
  GameplayComponent,
  onGoToNextExercise,
  onExerciseResult,
}: ExerciseShellProps<any>) => {
  const { config } = useContext(configStore);
  const [animateFeedback, setAnimateFeedback] = useState<boolean>(false);

  const gpWrapper = useRef<HTMLDivElement>(null)
  const feedback = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (state.status !== "solved") return;
    
    setTimeout(() => {
      setAnimateFeedback(true);
    });
  }, [state]);

  return (
    <div id="shell-container">
      {instruction ? (
        <div id="instruction">
          <div className="content">
            <p>{parse(instruction)}</p>
          </div>
        </div>
      ) : null}

      <div id="gp-container" style={{paddingBottom: feedback.current ? feedback.current.clientHeight + 24 : 0}}>
        <div id="gp-wrapper" className={state.status === "solving" ? "solved" : ""} ref={gpWrapper}>
          <GameplayComponent
            data={data}
            showCorrectAnswer={
              state.status === "solving"
                ? false
                : (state as SolvedExerciseState).next === "retry"
                ? false
                : defaultShowCorrectAnswer
            }
            i18n={i18n}
            onComplete={(result) => {
              onExerciseResult(result);
            }}
            onTrackingEvent={(statement: MinixAPIStatement) => {
              // TODO: Handle tracking when AI should be implemented
              console.log("Tracking:", statement);
            }}
          />
        </div>

        {state.status === "solved" && state.feedback ? (
          <div id="feedback-container" className={animateFeedback ? "--animate-feedback" : ""} ref={feedback}>
            <FeedbackDisplay
              isCorrect={state.result}
              title={state.feedback.title}
              text={state.feedback.text}
              image={state.feedback.image}
              imageAlt={state.feedback.imageAlt}
            />

            <div className="navigation-button">
              <Button
                style="primary"
                label={
                  state.next === "endOfPlaylist"
                    ? config.i18n.exerciseShell.feedback.endPlaylist
                    : state.next === "newExercise"
                    ? config.i18n.exerciseShell.feedback.nextExercise
                    : state.next === "retry"
                    ? config.i18n.exerciseShell.feedback.retry
                    : config.i18n.exerciseShell.close
                }
                icon={state.next === "endOfPlaylist" ? "" : "arrow_forward"}
                centeredLabel={state.next === "endOfPlaylist" ? true : false}
                onClick={() => {
                  onGoToNextExercise();
                }}
              />
            </div>
          </div>
        ) : null}
      </div>

      {/*specimen only*/}
      {hierarchy ? (
        <ExerciseIdentifier
          moduleID={hierarchy.moduleID}
          objectiveID={hierarchy.objectiveID}
          exerciseID={hierarchy.exerciseID}
        />
      ) : null}
      {/* -- specimen only*/}
    </div>
  );
};

export default ExerciseShell;
