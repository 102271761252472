import React from "react";
import { ExerciseState } from "../../interfaces/Data";
import TeacherProgressBar from "../TeacherProgressBar/TeacherProgressBar";

export interface ProgressBarProps {
  exercisesState: ExerciseState[];
  currentExerciseIndex?: number;
}

/**
 * Returns the correct progress bar depending on the user type.
 * 
 * NB: For now, it only returns the TeacherProgressBar. StudentProgressBar is
 * also available.
 */
const ProgressBar = (props: ProgressBarProps) => {
  return <TeacherProgressBar  {...props} />
};

export default ProgressBar;
